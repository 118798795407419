import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import styles from '../css/MinesCss.module.css';

const multipliers = {
  3: [1.01, 1.03, 1.05, 1.08, 1.11, 1.14, 1.18, 1.22, 1.26, 1.31, 1.36, 1.41, 1.46, 1.51, 1.56, 1.61, 1.67, 1.73, 1.79, 1.85, 2.0, 3.0],
  5: [1.08, 1.13, 1.21, 1.3, 1.8, 2.14, 2.28, 2.42, 2.66, 2.81, 3.06, 3.21, 3.46, 3.74, 3.96, 4.21, 4.57, 4.73, 4.89, 5.0],
  7: [1.38, 1.93, 2.21, 2.5, 2.8, 3.14, 3.28, 4.42, 4.66, 4.81, 5.06, 5.21, 5.46, 5.74, 5.96, 6.21, 6.57, 7.0],
  12: [1.78, 2.93, 3.21, 4.1, 5.8, 6.14, 6.98, 7.22, 7.66, 8.12, 8.73, 9.3, 10.0],
};

const MinesGame = () => {
  const gridSize = 5;
  const [board, setBoard] = useState([]);
  const [coins, setCoins] = useState(0);
  const [bet, setBet] = useState(100);
  const [bombs, setBombs] = useState(3);
  const [gameOver, setGameOver] = useState(false);
  const [safeTiles, setSafeTiles] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const db = getFirestore();
          const userDoc = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setCoins(userSnap.data().coins || 0);
          } else {
            console.error('User document not found');
          }
        } else {
          console.error('No user is logged in');
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  const updateCoinsInFirebase = async (newCoins) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const userDoc = doc(db, 'users', user.uid);
        await updateDoc(userDoc, { coins: newCoins });
      } else {
        console.error('No user is logged in');
      }
    } catch (error) {
      console.error('Error updating coins in Firebase:', error);
    }
  };

  const generateBoard = () => {
    if (bet > coins) return alert("Insufficient coins for this bet!");
    const newBoard = Array(gridSize * gridSize).fill({ hasMine: false, revealed: false });
    let minesPlaced = 0;
    while (minesPlaced < bombs) {
      const randomIndex = Math.floor(Math.random() * newBoard.length);
      if (!newBoard[randomIndex].hasMine) {
        newBoard[randomIndex] = { ...newBoard[randomIndex], hasMine: true };
        minesPlaced++;
      }
    }
    setBoard(newBoard);
    setSafeTiles(0);
    setGameOver(false);
    setGameStarted(true);
    const updatedCoins = coins - bet;
    setCoins(updatedCoins);
    updateCoinsInFirebase(updatedCoins);
  };

  const handleTileClick = (index) => {
    if (!gameStarted || gameOver || board[index].revealed) return;

    const newBoard = [...board];
    newBoard[index] = { ...newBoard[index], revealed: true };

    if (newBoard[index].hasMine) {
      setGameOver(true);
      setBet(0);
      revealAllTiles(newBoard);
    } else {
      setSafeTiles(safeTiles + 1);
      setBoard(newBoard);
    }
  };

  const revealAllTiles = (newBoard) => {
    const revealedBoard = newBoard.map((tile) => ({ ...tile, revealed: true }));
    setBoard(revealedBoard);
  };

  const handleBet = () => {
    if (gameOver || !gameStarted || safeTiles === 0) return;

    const multiplier = multipliers[bombs][safeTiles] || 1;
    const winnings = Math.floor(bet * multiplier);
    const updatedCoins = coins + winnings;
    setCoins(updatedCoins);
    updateCoinsInFirebase(updatedCoins);
    setGameOver(true);
    setGameStarted(false);
    revealAllTiles(board);
  };

  const resetGame = () => {
    setGameOver(false);
    setGameStarted(false);
    setBet(100);
    setBoard([]);
  };

  useEffect(() => {
    setGameStarted(false);
  }, [bombs]);

  return (
    <div className={styles.app}>
      <h1 className={styles.title}>Mines Game</h1>
      <p className={styles.coins}>Coins: {coins}</p>
      <div className={styles.controls}>
        <label>
          Bet Amount: 
          <input
            type="number"
            value={bet}
            onChange={(e) => setBet(Math.min(coins, Number(e.target.value)))}
            disabled={gameStarted}
          />
        </label>
        <label>
          Bombs: 
          <select value={bombs} onChange={(e) => setBombs(Number(e.target.value))} disabled={gameStarted}>
            <option value={3}>3 Bombs</option>
            <option value={5}>5 Bombs</option>
            <option value={7}>7 Bombs</option>
            <option value={12}>12 Bombs</option>
          </select>
        </label>
      </div>
      <button className={styles.button} onClick={generateBoard} disabled={gameStarted}>Start Game</button>
      <div
        className={styles.gameBoard}
        style={{ gridTemplateColumns: `repeat(${gridSize}, 60px)` }}
      >
        {board.map((tile, index) => (
          <div
            key={index}
            className={`${styles.tile} ${tile.revealed ? styles.revealed : ''} ${tile.hasMine ? styles.mine : ''}`}
            onClick={() => handleTileClick(index)}
          >
            {tile.revealed && (tile.hasMine ? '💣' : '✓')}
          </div>
        ))}
      </div>
      <button className={styles.button} onClick={handleBet} disabled={gameOver || !gameStarted}>Cash Out</button>
      <button className={styles.button} onClick={resetGame}>Reset Game</button>
      {gameOver && <p className={styles.status}>Game Over! {safeTiles} safe tiles revealed.</p>}
    </div>
  );
};

export default MinesGame;
