import React from "react";
import { Link } from "react-router-dom";
import styles from '../../css/Footer.module.css';

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.sponsored}>
                <span>Sponsored:</span>
                <ul className={styles.sponsoredList}>
                    <li>Thiapory Trades</li>
                    <li>Apexi Arbitrage Trading</li>
                    <li>Sparadisk LTD</li>
                </ul>
            </div>
            <ul>
                <Link to="/about"><li>About</li></Link>
                <Link to="/tos"><li>Term of Service</li></Link>
                <Link to="/pp"><li>Privacy Policy</li></Link>
            </ul>
        </div>
    );
}

export default Footer;
