import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import styles from '../css/Dice.module.css';
import Dice1 from "../assets/dice/dice-1.jpg";
import Dice2 from "../assets/dice/dice-2.jpg";
import Dice3 from "../assets/dice/dice-3.jpg";
import Dice4 from "../assets/dice/dice-4.jpg";
import Dice5 from "../assets/dice/dice-5.jpg";
import Dice6 from "../assets/dice/dice-6.jpg";

const DiceGame = () => {
    const [diceArray, setDiceArray] = useState([Dice1, Dice2, Dice3, Dice4, Dice5, Dice6]);
    const [coins, setCoins] = useState(0); // Default coins
    const [betAmount, setBetAmount] = useState(100); // Default bet
    const [guess, setGuess] = useState(1); // Default guess
    const [rolling, setRolling] = useState(false);
    const [diceNumber, setDiceNumber] = useState(1);
    const [gameMessage, setGameMessage] = useState('');

    useEffect(() => {
        const fetchCoins = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    const db = getFirestore();
                    const userDoc = doc(db, 'users', user.uid);
                    const userSnap = await getDoc(userDoc);
                    if (userSnap.exists()) {
                        setCoins(userSnap.data().coins || 0);
                    } else {
                        console.error('User document not found');
                    }
                } else {
                    console.error('No user is logged in');
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
        };

        fetchCoins();
    }, []);

    const updateCoinsInFirebase = async (newCoins) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const db = getFirestore();
                const userDoc = doc(db, 'users', user.uid);
                await updateDoc(userDoc, { coins: newCoins });
                console.log('Coins updated in Firebase');
            } else {
                console.error('No user is logged in');
            }
        } catch (error) {
            console.error('Error updating coins:', error);
        }
    };

    const rollDice = async () => {
        if (betAmount > coins) {
            setGameMessage('Insufficient coins!');
            return;
        }

        setRolling(true);
        setGameMessage('');

        // Simulate dice rolling animation
        const rollInterval = setInterval(() => {
            setDiceNumber(Math.floor(Math.random() * 6) + 1); // Random dice face 1-6
        }, 100);

        setTimeout(async () => {
            clearInterval(rollInterval);
            const finalNumber = Math.floor(Math.random() * 6) + 1;
            setDiceNumber(finalNumber);
            setRolling(false);

            if (guess === finalNumber) {
                const winAmount = betAmount * 6; // 6x multiplier for correct guess
                const newCoins = coins + winAmount;
                setCoins(newCoins);
                await updateCoinsInFirebase(newCoins);
                setGameMessage(`Correct guess! You won ${winAmount} coins! 🎉`);
            } else {
                const newCoins = coins - betAmount;
                setCoins(newCoins);
                await updateCoinsInFirebase(newCoins);
                setGameMessage(`Wrong guess! The dice showed ${finalNumber}. You lost ${betAmount} coins. 😢`);
            }
        }, 2000); // Roll for 2 seconds
    };

    const handleBetChange = (e) => {
        setBetAmount(Number(e.target.value));
    };

    const handleGuessChange = (e) => {
        setGuess(Number(e.target.value));
    };

    return (
        <div className={styles.gameContainer}>
            <h1 className={styles.title}>Stake Dice Game 🎲</h1>
            <p className={styles.coins}>Coins: {coins}</p>
            <div className={styles.betSection}>
                <label htmlFor="betAmount">Bet Amount: </label>
                <input
                    type="number"
                    id="betAmount"
                    value={betAmount}
                    onChange={handleBetChange}
                    min="1"
                    max={coins}
                    className={styles.input}
                />
            </div>
            <div className={styles.guessSection}>
                <label htmlFor="guess">Guess the Number (1-6): </label>
                <input
                    type="number"
                    id="guess"
                    value={guess}
                    onChange={handleGuessChange}
                    min="1"
                    max="6"
                    className={styles.input}
                />
            </div>
            <div className={styles.diceContainer}>
                <div className={`${styles.dice} ${rolling ? styles.rolling : ''}`}>
                    <img
                        src={diceArray[diceNumber - 1]}
                        width={80}
                        alt={`Dice showing ${diceNumber}`}
                        className={styles.diceImage}
                    />
                </div>
            </div>
            <button onClick={rollDice} disabled={coins <= 0 || rolling} className={styles.button}>Roll Dice</button>
            <p className={styles.message}>{gameMessage}</p>
        </div>
    );
};

export default DiceGame;
