import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import styles from '../css/RPS.module.css';

const RockPaperScissors = () => {
  const [adder, setAdder] = useState(0)
  const [multiplication, setMultiplication] = useState("")
  const [coins, setCoins] = useState(0);
  const [bet, setBet] = useState(0);
  const [playerChoice, setPlayerChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState('');

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const db = getFirestore();
          const userDoc = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setCoins(userSnap.data().coins || 0);
          } else {
            console.error('User document not found');
          }
        } else {
          console.error('No user is logged in');
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  const choices = ['rock', 'paper', 'scissors'];

  const playGame = async (choice) => {
    if (bet <= 0 || bet > coins) {
      setResult('Invalid bet amount.');
      return;
    }

    const computerChoice = choices[Math.floor(Math.random() * 3)];
    setPlayerChoice(choice);
    setComputerChoice(computerChoice);

    let outcome;
    if (choice === computerChoice) {
      outcome = 'draw';
      setAdder(0)
      setMultiplication("x1")
    } else if (
      (choice === 'rock' && computerChoice === 'scissors') ||
      (choice === 'scissors' && computerChoice === 'paper') ||
      (choice === 'paper' && computerChoice === 'rock') 
    ) {
      outcome = 'win';
      setAdder(adder + 1)
    } else {
      outcome = 'lose';
      setAdder(0)
      setMultiplication("x0")
    }

    setResult(outcome);

    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const db = getFirestore();
      const userDoc = doc(db, 'users', user.uid);

      try {
        if (outcome === 'win') {
          let newBet = 0
          switch (adder) {
            case 0:
              newBet = bet * 2;
              setMultiplication("x2")
              break;
            case 1:
              newBet = bet * 2
              setMultiplication("x2")
              break;
            case 2:
              newBet = bet * 2.5
              setMultiplication("x2.5")
              break
            case 3:
              newBet = bet * 3
              setMultiplication("x3")
              break
            case 4:
              newBet = bet * 3.5
              setMultiplication("x3.5")
              break
            case 5:
              newBet = bet * 4
              setMultiplication("x4")
              break
            case 6:
              newBet = bet * 4.5
              setMultiplication("x4.5")
              break
            case 7:
              newBet = bet * 5
              setMultiplication("x5")
              break
          }
          const newCoins = coins + newBet;
          setCoins(newCoins);
          await updateDoc(userDoc, { coins: newCoins });
        } else if (outcome === 'lose') {
          const newCoins = coins - bet;
          setCoins(newCoins);
          await updateDoc(userDoc, { coins: newCoins });
        }
      } catch (error) {
        console.error('Error updating coins:', error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Rock Paper Scissors</h1>
      <p className={styles.coins}>Coins: {coins}</p>
      <input
        type="number"
        placeholder="Bet amount"
        value={bet}
        onChange={(e) => setBet(Number(e.target.value))}
        className={styles.input}
      />
      <div className={styles.choices}>
        {choices.map((choice) => (
          <button
            key={choice}
            className={styles.choiceButton}
            onClick={() => playGame(choice)}
          >
            {choice}
          </button>
        ))}
      </div>
      {playerChoice && computerChoice && (
        <div className={styles.results}>
          <p>You chose: {playerChoice}</p>
          <p>Computer chose: {computerChoice}</p>
          <p>Result: {result}</p>
          <p>Multiplication: {multiplication}</p>
        </div>
      )}
    </div>
  );
};

export default RockPaperScissors;
