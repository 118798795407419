import Slots from "./components/Slots"
import AuthPage from "./components/AuthForm";
import {useAuthState} from "react-firebase-hooks/auth"
import {auth} from "./firebaseConfig";
import Home from "./components/Home"
import EmailVerify from "./components/EmailVerify"
import {Routes, Route} from "react-router-dom"
import Guess from "./components/Guess";
import About from "./components/About";
import Tos from "./components/Tos";
import Pp from "./components/Pp";
import Footer from "./components/subComponents/footer";
import Header from "./components/subComponents/Header";
import RefSys from "./components/RefSys";
import Explanation from "./components/Explanation";
import Styles from "./css/Auth.module.css"
import MinesGame from "./components/MinesGame";
import DiceGame from "./components/DiceGame";
import WheelOfFortune from "./components/WheelOfFortune";
import RockPaperScissors from "./components/RPS";
import SnakeGame from "./components/SnakeGame";
function App() {
  const [user] = useAuthState(auth)
  return (
<>
<Header />
<Routes >
{user ? (
          user.emailVerified ? (
            <Route path="/" element={<Slots />} />
          ) : (
            <Route path="/" element={<EmailVerify />} />
          )
        ) : (
          <Route path="/" element={<Home />} />
        )}

      
      <Route path="/signin" element={<AuthPage/>}/>
      <Route path="/guess" element={<Guess/>}/>
      <Route path="/mines" element={<MinesGame/>}/>
      <Route path="/dice" element={<DiceGame/>}/>
      <Route path="/wheel-of-fortune" element={<WheelOfFortune/>}/>
      <Route path="/rps" element={<RockPaperScissors/>}/>
      <Route path="/snake-game" element={<SnakeGame/>}/>
      <Route path="/tos" element={<Tos/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/pp" element={<Pp/>}/>
      <Route path="/referral" element={<RefSys/>}/>
      <Route path="/exp" element={<Explanation/>}/>
    </Routes>
      <Footer />
      </>

    
  );
}


export default App;
