import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { auth } from "../firebaseConfig";
import styles from '../css/Tos.module.css';

function RefSys() {
    const [referralCode, setReferralCode] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReferralCode = async () => {
            try {
                
                const currentUser = auth.currentUser; // Get the current authenticated user
                
                if (currentUser) {
                    const userId = currentUser.uid; // Get the user ID
                    const db = getFirestore(); // Initialize Firestore
                    const userDoc = doc(db, "users", userId); // Reference to the user's document
                    const userSnapshot = await getDoc(userDoc); // Fetch the document

                    if (userSnapshot.exists()) {
                        const data = userSnapshot.data();
                        setReferralCode(data.referralCode || "No referral code found");
                    } else {
                        setReferralCode("User document not found");
                    }
                } else {
                    setReferralCode("User not logged in");
                }
            } catch (error) {
                console.error("Error fetching referral code:", error);
                setReferralCode("Error fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchReferralCode();
    }, []);

    return (
        <div className={styles.container}>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>Stand a chance to win big with you and a friend! If you generate a referral code, and the person who sent it wins the prize money, you'll also win big simply for referring them! A win win situation. All you have to do is generate your referral code and share the website. Easiest money you could make!</strong>
            </p>
            <p>Your referral code: {referralCode}</p>
        </div>
    );
}

export default RefSys;