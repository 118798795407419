import React, { useState, useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import styles from '../css/SnakeGame.module.css';

const SnakeGame = () => {
  const [snake, setSnake] = useState([[5, 5]]); // Snake starts with one segment
  const [direction, setDirection] = useState('RIGHT'); // Initial direction
  const [apple, setApple] = useState([10, 10]); // Initial apple position
  const [coins, setCoins] = useState(0);
  const [gridSize] = useState(20);
  const [isGameOver, setIsGameOver] = useState(false);

  const gameInterval = useRef(null);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const db = getFirestore();
          const userDoc = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setCoins(userSnap.data().coins || 0);
          } else {
            console.error('User document not found');
          }
        } else {
          console.error('No user is logged in');
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          if (direction !== 'DOWN') setDirection('UP');
          break;
        case 'ArrowDown':
          if (direction !== 'UP') setDirection('DOWN');
          break;
        case 'ArrowLeft':
          if (direction !== 'RIGHT') setDirection('LEFT');
          break;
        case 'ArrowRight':
          if (direction !== 'LEFT') setDirection('RIGHT');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [direction]);

  useEffect(() => {
    if (!isGameOver) {
      gameInterval.current = setInterval(() => {
        moveSnake();
      }, 200);

      return () => clearInterval(gameInterval.current);
    }
  }, [direction, isGameOver, snake]);

  const startGame = () => {
    setSnake([[5, 5]]);
    setDirection('RIGHT');
    setApple([10, 10]);
    setIsGameOver(false);
  };

  const moveSnake = () => {
    const newSnake = [...snake];
    const head = [...newSnake[newSnake.length - 1]];
  
    switch (direction) {
      case 'UP':
        head[1] -= 1;
        break;
      case 'DOWN':
        head[1] += 1;
        break;
      case 'LEFT':
        head[0] -= 1;
        break;
      case 'RIGHT':
        head[0] += 1;
        break;
      default:
        break;
    }
  
    // Wrap around the grid if the snake goes out of bounds
    head[0] = (head[0] + gridSize) % gridSize; // Wrap horizontally
    head[1] = (head[1] + gridSize) % gridSize; // Wrap vertically
  
    // Check for collisions with itself
    if (newSnake.some(([x, y]) => x === head[0] && y === head[1])) {
      setIsGameOver(true);
      clearInterval(gameInterval.current);
      return;
    }
  
    newSnake.push(head);
  
    if (head[0] === apple[0] && head[1] === apple[1]) {
      setApple([Math.floor(Math.random() * gridSize), Math.floor(Math.random() * gridSize)]);
      addCoin();
    } else {
      newSnake.shift(); // Remove the tail if no apple was eaten
    }
  
    setSnake(newSnake);
  };
  

  const addCoin = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getFirestore();
      const userDoc = doc(db, 'users', user.uid);

      try {
        const newCoins = coins + 1;
        setCoins(newCoins);
        await updateDoc(userDoc, { coins: newCoins });
      } catch (error) {
        console.error('Error updating coins:', error);
      }
    }
  };

  const handleMobileControl = (newDirection) => {
    if (
      (newDirection === 'UP' && direction !== 'DOWN') ||
      (newDirection === 'DOWN' && direction !== 'UP') ||
      (newDirection === 'LEFT' && direction !== 'RIGHT') ||
      (newDirection === 'RIGHT' && direction !== 'LEFT')
    ) {
      setDirection(newDirection);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Snake Game</h1>
      <p className={styles.coins}>Coins: {coins}</p>
      {isGameOver ? (
        <>
          <button className={styles.startButton} onClick={startGame}>Restart Game</button>
          <p className={styles.gameOver}>Game Over!</p>
        </>
      ) : (
        <div className={styles.grid} style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
          {Array.from({ length: gridSize * gridSize }).map((_, idx) => {
            const x = idx % gridSize;
            const y = Math.floor(idx / gridSize);
            const isSnake = snake.some(([sx, sy]) => sx === x && sy === y);
            const isApple = apple[0] === x && apple[1] === y;

            return (
              <div
                key={idx}
                className={
                  isSnake
                    ? styles.snake
                    : isApple
                    ? styles.apple
                    : styles.cell
                }
              ></div>
            );
          })}
        </div>
      )}
      <div className={styles.controls}>
        <button onClick={() => handleMobileControl('UP')}>Up</button>
        <div>
          <button onClick={() => handleMobileControl('LEFT')}>Left</button>
          <button onClick={() => handleMobileControl('RIGHT')}>Right</button>
        </div>
        <button onClick={() => handleMobileControl('DOWN')}>Down</button>
      </div>
    </div>
  );
};

export default SnakeGame;
