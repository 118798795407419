import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Styles from "../css/Auth.module.css";

const Guess = () => {
  const [secretString, setSecretString] = useState('');
  const [result, setResult] = useState('');
  const [generatedString, setGeneratedString] = useState('');
  const [coins, setCoins] = useState(0); // To store the user's coins
  const [isCooldown, setIsCooldown] = useState(false); // Cooldown state
  const [manualGuess, setManualGuess] = useState(''); // To store manual guess input
  const [manualGuessValid, setManualGuessValid] = useState(true); // To check if manual guess is valid
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchSecretString = async (user) => {
      try {
        const userDoc = await getDoc(doc(db, 'game', "secretstring"));
        if (userDoc.exists()) {
          const secret = userDoc.data().secret || '';
          setSecretString(secret);
        }
      } catch (error) {
        console.error("Error fetching secret string:", error);
      }
    };

    const fetchUserData = async (user) => {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setCoins(userDoc.data().coins); // Fetch user's current coin balance
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSecretString(user);
        fetchUserData(user);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < 8; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  };

  const updateCoins = async (user) => {
    try {
      if (coins > 0) {
        await updateDoc(doc(db, 'users', user.uid), {
          coins: coins - 1, // Deduct 1 coin
        });
        setCoins(coins - 1); // Update local coin count
      }
    } catch (error) {
      console.error("Error updating coins:", error);
    }
  };

  const checkMatch = () => {
    if (isCooldown) {
      alert('Please wait for the cooldown to finish.');
      return;
    }

    if (coins === 0) {
      alert('You have 0 coins. Please recharge to continue guessing.');
      return;
    }

    let guess = manualGuess || generateRandomString(); // Use manual guess if available
    setGeneratedString(guess);

    const user = auth.currentUser;
    if (user) {
      updateCoins(user); // Deduct 1 coin when the user guesses

      if (guess === secretString) {
        setResult('🎉 Correct Guess! You matched the secret phrase!');
      } else {
        setResult('❌ Incorrect guess. Try again!');
      }
    }

    // Start 3-second cooldown
    setIsCooldown(true);
    setTimeout(() => setIsCooldown(false), 3000);
  };

  const handleManualGuessChange = (e) => {
    const input = e.target.value;
    setManualGuess(input);

    // Check if the input matches the regex for 7 characters
    const isValid = /^[A-Za-z0-9]{7}$/.test(input);
    setManualGuessValid(isValid);
  };

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", background: "var(--background)", borderRadius: "20px", color: "var(--primary)"}}>
      <button 
        onClick={checkMatch} 
        className={Styles.button} 
        disabled={isCooldown}
        style={{ opacity: isCooldown ? 0.6 : 1 }}
      >
        {isCooldown ? 'Cooldown... Please wait' : 'Generate & Check'}
      </button>

      <div className="generated" style={{ color: "var(--primary)", fontWeight: "bold" }}>
        Generated Code: {generatedString || 'Press the button to generate a code'}
      </div>

      <div className="result" style={{ color: "var(--primary)", fontWeight: "bold" }}>
        {result}
      </div>

      <div className="coins" style={{ color: "var(--primary)", fontWeight: "bold" }}>
        Coins: {coins}
      </div>

      <div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
        <input
          type="text"
          value={manualGuess}
          onChange={handleManualGuessChange}
          maxLength={7}
          placeholder="Enter your 7-character guess"
          style={{ padding: '10px', fontSize: '16px',  marginLeft: "auto", marginRight: "auto"  }}
        />
        {!manualGuessValid && <p style={{ color: 'red' }}>Guess must be exactly 7 characters.</p>}
        <p style={{ textWrap: "wrap"}}> 1000 USD for the winner without refferal.
        If reffered 1500 USD for the winner and 200 USD for the referrer..</p>
      </div>
    </div>
  );
};

export default Guess;
