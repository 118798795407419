import React, { useState, useEffect, useRef } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDocs, query, collection, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import Footer from "./subComponents/footer";
import Styles from "../css/Auth.module.css"; // Make sure your styles handle canvas behind the form

const ParticleCanvas = () => {
  const canvasRef = useRef(null);
  const particles = useRef([]);

  const resizeCanvas = (canvas, context) => {
    const oldWidth = canvas.width;
    const oldHeight = canvas.height;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  
    particles.current.forEach((particle) => {
      particle.x = (particle.x / oldWidth) * canvas.width;
      particle.y = (particle.y / oldHeight) * canvas.height;
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const createParticles = () => {
      for (let i = 0; i < 100; i++) {
        particles.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          opacity: Math.random() * 0.4 + 0.1,
          speed: Math.random() * 1.2 + 1,
          direction: Math.random() < 0.5 ? 1 : -1,
        });
      }
    };

    const drawParticle = (particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(255, 255, 255, ${particle.opacity})`;
      ctx.fill();
    };

    const animateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.current.forEach((particle) => {
        particle.y += particle.direction * particle.speed;
        particle.x += Math.random() * 2 - 1;

        if (particle.direction === 1 && particle.y > canvas.height + particle.size) {
          particle.y = -particle.size;
          particle.x = Math.random() * canvas.width;
        } else if (particle.direction === -1 && particle.y < -particle.size) {
          particle.y = canvas.height + particle.size;
          particle.x = Math.random() * canvas.width;
        }

        drawParticle(particle);
      });

      requestAnimationFrame(animateParticles);
    };

    createParticles();
    animateParticles();

    resizeCanvas(canvas, ctx);

    window.addEventListener("resize", () => resizeCanvas(canvas, ctx));

    return () => {
      window.removeEventListener("resize", () => resizeCanvas(canvas, ctx));
    };
  }, []);

  return <canvas ref={canvasRef} id="particle-canvas"></canvas>;
};

const AuthPage = () => {
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPass, setSignInPass] = useState("");

  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPass, setSignUpPass] = useState("");
  const [signUpUserName, setSignUpUserName] = useState("");

  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const db = getFirestore();

  const generateReferralCode = () => {
    // Generate a random 9-digit number
    return Math.floor(100000000 + Math.random() * 900000000).toString();
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, signUpEmail, signUpPass);
      const user = userCredential.user;
  
      // Send email verification
      await sendEmailVerification(user);
  
      // Generate a unique referral code
      let referralCode;
      let codeExists = true;
  
      // Loop until a unique code is generated
      while (codeExists) {
        referralCode = generateReferralCode();
  
        // Check if the code already exists in Firestore
        const referralQuery = await getDocs(query(collection(db, "referralCodes"), where("code", "==", referralCode)));
        codeExists = !referralQuery.empty;  // If query is not empty, the code exists
      }
  
      // Add user to Firestore with initial coins and referral code
      const userRef = doc(db, "users", user.uid); 
      await setDoc(userRef, {
        email: signUpEmail,
        coins: 20, // Initial coins
        referralCode: referralCode, // Store the unique referral code
        createdAt: new Date(),
      });
  
      // Store the referral code in a separate collection to ensure uniqueness
      await setDoc(doc(db, "referralCodes", referralCode), {
        userId: user.uid,
        createdAt: new Date(),
      });
  
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(auth, signInEmail, signInPass);
      navigate("/");
    } catch (error) {
      alert(error);
    }
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={Styles["auth-page"]}>
      {/* Add the ParticleCanvas component here */}
      <ParticleCanvas />

      <div className={`${Styles.card} ${isFlipped ? Styles["card-flipped"] : ""}`}>
        {/* Front Side: Sign In */}
        <div className={Styles.front}>
          <form onSubmit={handleSignIn}>
            <div className={Styles["input-wrapper"]}>
              <input
                onChange={(e) => setSignInEmail(e.target.value)}
                value={signInEmail}
                type="email"
                id="email"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="email" className={Styles["text-inp-lbl"]}>
                Email
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles["input-wrapper"]}>
              <input
                onChange={(e) => setSignInPass(e.target.value)}
                value={signInPass}
                type="password"
                id="password"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="password" className={Styles["text-inp-lbl"]}>
                Password
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles["check-wrapper"]}>
              <input type="checkbox" id="rememberMe" />
              <span className={Styles.checkmark}></span>
              <label htmlFor="rememberMe">Remember Me</label>
            </div>
            <div className={Styles.submit}>
              <button type="submit" className={Styles.button}>
                Sign In
              </button>
            </div>
            <div className={Styles["switch-link"]} onClick={handleFlip}>
              Don’t have an account? Sign Up
            </div>
          </form>
        </div>

        {/* Back Side: Sign Up */}
        <div className={Styles.back}>
          <form onSubmit={handleSignUp}>
            <div className={Styles["input-wrapper"]}>
              <input
                onChange={(e) => setSignUpUserName(e.target.value)}
                value={signUpUserName}
                type="text"
                id="username"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="username" className={Styles["text-inp-lbl"]}>
                Username
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles["input-wrapper"]}>
              <input
                onChange={(e) => setSignUpEmail(e.target.value)}
                value={signUpEmail}
                type="email"
                id="signup-email"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="signup-email" className={Styles["text-inp-lbl"]}>
                Email
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles["input-wrapper"]}>
              <input
                onChange={(e) => setSignUpPass(e.target.value)}
                value={signUpPass}
                type="password"
                id="signup-password"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="signup-password" className={Styles["text-inp-lbl"]}>
                Password
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles["input-wrapper"]}>
            <input
                type="test"
                name="ref"
                className={Styles["text-inp"]}
                placeholder=" "
              />
              <label htmlFor="ref" className={Styles["text-inp-lbl"]}>
                ReferralCode
              </label>
              <div className={Styles.line}></div>
            </div>
            <div className={Styles.submit}>
              <button type="submit" className={Styles.button}>
                Sign Up
              </button>
            </div>
            <div className={Styles["switch-link"]} onClick={handleFlip}>
              Already have an account? Sign In
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
