import React, { useState } from 'react';
import styles from '../../css/Header.module.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logo}>Volceria</div>
        <nav className={`${styles.nav} ${isMenuOpen ? styles.active : ''}`}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link to="/">Home</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/guess">Guess</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/mines">mines</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/dice">dice</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/wheel-of-fortune">Wheel</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/referral">Referral Code</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/exp">Game Explanation</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/rps">rps</Link>
            </li>
            <li className={styles.navItem}>
            <Link to="/snake-game">snake</Link>
            </li>
          </ul>
          <button 
          className={styles.menuToggle} 
          onClick={toggleMenu} 
          aria-label="Toggle menu">
          ☰
        </button>
        </nav>
        <button 
          className={styles.menuToggle} 
          onClick={toggleMenu} 
          aria-label="Toggle menu">
          ☰
        </button>
      </div>
    </header>
  );
};

export default Header;
