import React from "react";
import styles from '../css/Tos.module.css';

function Explanation() {
    return (
        <div className={styles.container}>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>Welcome to a simple game with an even more simple way to make real money! All you have to do is guess the password of 7 digits with 36 possible characters. 0 to 9 and a to z, case sensitive! However, you can't just guess, that would be too easy. You have a limited amount of coins, but you can earn more for free by playing our slot machine game! We wish you the best of luck. </strong>
            </p>
        </div>
    );
}

export default Explanation;
