import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import styles from '../css/WheelOfFortune.module.css';

const WheelOfFortune = () => {
  const [coins, setCoins] = useState(0);
  const [bet, setBet] = useState(0);
  const [message, setMessage] = useState('');
  const [wheelResult, setWheelResult] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);

  const outcomes = [
    { label: 'x\n5', multiplier: 5 },
    { label: 'x\n3', multiplier: 3 },
    { label: 'x\n2', multiplier: 2 },
    { label: 'x\n1.5', multiplier: 1.5 },
    { label: 'x\n0.75', multiplier: 0.75 },
    { label: 'x\n0.5', multiplier: 0.5 },
    { label: 'x\n0.25', multiplier: 0.25 },
    { label: 'x\n0.1', multiplier: 0.1 },
    { label: 'x\n1', multiplier: 1 },
    { label: 'x\n4', multiplier: 4 },
    { label: 'x\n2.5', multiplier: 2.5 },
    { label: 'x\n0.6', multiplier: 0.6 },
    { label: 'x\n1', multiplier: 1 },
    { label: 'x\n1.2', multiplier: 1.2 },
    { label: 'x\n0.8', multiplier: 0.8 },
    { label: 'x\n6', multiplier: 6 },
  ];

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const db = getFirestore();
          const userDoc = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            setCoins(userSnap.data().coins || 0);
          } else {
            console.error('User document not found');
          }
        } else {
          console.error('No user is logged in');
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  const updateCoinsInDB = async (newCoins) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const db = getFirestore();
      const userDoc = doc(db, 'users', user.uid);
      try {
        await updateDoc(userDoc, { coins: newCoins });
      } catch (error) {
        console.error('Error updating coins:', error);
      }
    }
  };

  const handleSpin = () => {
    if (bet <= 0 || bet > coins) {
      setMessage('Invalid bet amount!');
      return;
    }

    setIsSpinning(true);

    const randomDegree = Math.random() * 360 + 720; // At least 2 full spins
    const newRotation = rotationDegree + randomDegree;

    setRotationDegree(newRotation);
    setTimeout(() => {
      const totalRotation = newRotation % 360;
      const anglePerSegment = 360 / outcomes.length;
      const segmentIndex = Math.floor((360 - totalRotation) / anglePerSegment) % outcomes.length;

      const result = outcomes[segmentIndex];
      const winnings = Math.max(bet * result.multiplier, 0); // Prevent negative winnings

      const newCoins = coins - bet + winnings;
      setCoins(newCoins);
      updateCoinsInDB(newCoins);

      setMessage(
        result.multiplier === 1
          ? `No loss! Your bet is returned.`
          : `You won: ${winnings.toFixed(2)} coins!`
      );
      setWheelResult(result);
      setIsSpinning(false);
    }, 3000); // Spin duration
  };

  return (
    <div className={styles.wheelOfFortune}>
      <h1>Wheel of Fortune</h1>
      <p>Your Coins: {coins}</p>
      <input
        type="number"
        placeholder="Enter your bet"
        value={bet}
        onChange={(e) => setBet(Number(e.target.value))}
        className={styles.input}
      />
      <button
        onClick={handleSpin}
        className={`${styles.button} ${isSpinning ? styles.disabled : ''}`}
        disabled={isSpinning}
      >
        {isSpinning ? 'Spinning...' : 'Spin the Wheel'}
      </button>
      {wheelResult && (
        <div className={styles.result}>
          <p>Result: {wheelResult.label}</p>
        </div>
      )}
      {message && <p className={styles.message}>{message}</p>}
      <div className={styles.wheelContainer}>
        <div className={styles.arrow}></div>
        <div
          className={`${styles.wheel} ${isSpinning ? styles.spinning : ''}`}
          style={{
            transform: `rotate(${rotationDegree}deg)`,
            transitionDuration: '3s',
          }}
        >
          {outcomes.map((outcome, index) => (
            <div
              key={index}
              className={styles.segment}
              style={{
                transform: `rotate(${(360 / outcomes.length) * index}deg) skewY(-60deg)`,
              }}
            >
              <span
                style={{
                  transform: `rotate(${360 / outcomes.length / 2}deg) skewY(60deg)`,
                }}
              >
                {outcome.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WheelOfFortune;
